<template>
    <div>
      <PageLoader v-bind:storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#30B868" left :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
  
      <v-dialog
        v-model="cropImageDialog"
        persistent
        :width="
          $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
            ? '100vw'
            : $vuetify.breakpoint.name == 'md'
            ? '80vw'
            : $vuetify.breakpoint.name == 'lg'
            ? '50vw'
            : '40vw'
        "
      >
        <v-card>
          <v-layout wrap justify-center pa-2>
            <v-flex xs12>
              <ImageCropper
                :cropwidth="350"
                :cropheight="400"
                :image="currentImage"
                :key="currentImage"
                @stepper="imageCropper"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-dialog>
      <v-layout wrap justify-start pt-4 px-2 px-lg-0 pl-lg-12 pl-xl-12>
        <v-flex xs12 py-4>
          <v-layout pt-3 wrap justify-start>
            <v-flex xs7 sm3 md3 lg2 xl2>
              <v-layout wrap justify-center>
                <v-flex xs12 sm12 text-left align-self-center>
                  <span
                    style="
                      font-family: poppinssemibold;
                      font-size: 20px;
                      color: #1c1c1c;
                    "
                  >
                    Edit Product
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- Main Container -->
        <v-flex xs12 md12 lg7 xl7>
          <v-layout wrap justify-start>
            <v-flex xs12 sm12 md12 lg12 xl12 text-left>
              <span style="font-family: poppinsmedium;"> Select Category type </span>
              <v-select
                outlined
                small-chips
                deletable-chips
                dense
                readonly
                v-model="product.category"
                color="#30B868"
                placeholder="Category"
                :items="categoryArray"
                item-text="name"
                item-value="_id"
                item-color="#30B868"
                hide-details
                v-on:input="subSize(product.category)"
              >
              </v-select>
            </v-flex>
            <!-- pr-sm-2  -->
            <v-flex xs12 sm12 text-left pt-2>
              <span style="font-family: poppinsmedium;"> Product Unique Id </span>
              <v-text-field
                v-model="product.productcode"
                color="#30B868"
                hide-selected
                outlined
                placeholder="Product Unique Id"
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 text-left pt-2>
              <span style="font-family: poppinsmedium;"> Name of the Product </span>
              <v-text-field
                v-model="product.productname"
                color="#30B868"
                hide-selected
                outlined
                placeholder="Name of the product"
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 text-left pt-2>
              <span style="font-family: poppinsmedium;"> Caption of the Product </span>
              <v-textarea
                rows="2"
                v-model="product.caption"
                color="#30B868"
                hide-selected
                hide-details
                outlined
                placeholder="Enter a few words about the product"
                dense
                counter
                maxlength="100"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 text-left pt-2>
              <span style="font-family: poppinsmedium;"> Description of the Product </span>
              <v-textarea
                rows="3"
                v-model="product.description"
                color="#30B868"
                hide-selected
                hide-details=""
                outlined
                placeholder="Describe the product"
                dense
                counter
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2>
              <span style="font-family: poppinsmedium;"> GST Percentage </span>
              <v-text-field
                v-model="product.gstPercentage"
                color="#30B868"
                hide-selected
                outlined
                placeholder="GST Percentage"
                dense
                hide-details
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 text-left pt-2 pl-2>
              <span style="font-family: poppinsmedium;">HSN Code </span>
              <v-text-field
                v-model="product.hsnCode"
                color="#30B868"
                hide-selected
                outlined
                placeholder="HSN Code"
                dense
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- Image Container -->
        <v-flex xs12 md12 lg4 xl3 pl-sm-4 pt-2>
          <v-layout wrap justify-center>
            <v-flex pt-4 xs12 text-left>
              <v-layout wrap justify-center>
                <v-flex xs8 sm6 md6 lg8>
                  <v-card flat tile outlined height="300px">
                    <v-layout wrap justify-center fill-height>
                      <v-flex md12 lg12 align-self-center>
                        <v-img
                          v-if="product.photos[0] || imageArray.length > 0"
                          :src="
                            product.photos[0]
                              ? mediaUURL + product.photos[0]
                              : imageArray[0]
                          "
                          height="298px"
                          contain
                        >
                          <v-layout wrap>
                            <v-flex text-right pa-2>
                              <v-avatar color="#FF3434" size="20">
                                <v-icon
                                  color="#FFF"
                                  small
                                  @click="
                                    product.photos[0]
                                      ? removePhoto(0)
                                      : removeImageArray(0)
                                  "
                                >
                                  mdi-close
                                </v-icon>
                              </v-avatar>
                            </v-flex>
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
                <v-flex xs4 sm3 md3 lg4>
                  <v-layout pt-0 wrap justify-center>
                    <v-flex
                      xs12
                      v-for="(item, i) in product.photos"
                      :key="i"
                      pa-1
                    >
                      <v-img
                        v-if="i > 0"
                        :src="mediaUURL + item"
                        height="85px"
                        contain
                      >
                        <v-layout wrap>
                          <v-flex text-right pa-0>
                            <v-avatar color="#FF3434" size="15">
                              <v-icon color="#FFF" small @click="removePhoto(i)">
                                mdi-close
                              </v-icon>
                            </v-avatar>
                          </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                    <v-flex xs12 v-for="(item, i) in imageArray" :key="i" pa-1>
                      <v-img :src="item" height="85px" contain>
                        <v-layout wrap>
                          <v-flex text-right pa-0>
                            <v-avatar color="#FF3434" size="15">
                              <v-icon
                                color="#FFF"
                                small
                                @click="removeImageArray(i)"
                              >
                                mdi-close
                              </v-icon>
                            </v-avatar>
                          </v-flex>
                        </v-layout>
                      </v-img>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm9 md9 lg12 pt-4 text-left>
              <v-btn
                width="180px"
                small
                tile
                depressed
                :ripple="false"
                class="text-capitalize"
                color="#EFEFEF"
                @click="$refs.files.click()"
              >
                <v-layout wrap justify-center>
                  <v-flex xs8 text-right>
                    <span style="font-size: 12px; font-family: opensanssemibold">
                      Upload Image
                    </span>
                  </v-flex>
                  <v-flex xs4 text-right>
                    <v-img
                      src="./../../../assets/iconsets/file-upload.svg"
                      height="15px"
                      contain
                    ></v-img>
                  </v-flex>
                </v-layout>
              </v-btn>
              <input
                v-show="false"
                accept="image/*"
                id="file"
                ref="files"
                multiple="multiple"
                type="file"
                @change="uploadImages"
              />
            </v-flex>
            <v-flex xs12>
              <span style="color: red; font-family: opensansbold; font-size: 14px"
                >Note : Please upload 350 (Width) * 400 (Height) Pixel
                Images</span
              >
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- Product Stock -->
        <v-flex xs12 md12 lg10 xl7>
          <v-layout wrap justify-center>
            <v-flex xs12 text-left>
              <span style=" font-family: poppinsbold;">Product Stock</span>
            </v-flex>
            <v-flex xs12 v-if="flag == true">
              <StockAdder
                v-bind:storage="subSizeArray"
                v-bind:sizeData="product.size"
                :key="subSizeArray[0]"
                @stepper="winStepper"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md12 lg7 xl7>
          <v-layout pt-2 wrap>
            <v-flex sm3 md3 lg3 xl3 text-left>
              <span style="font-family: poppinsmedium;"> Total Stock </span>
              <v-text-field
                :disabled="subSizeArray.length > 0 ? true : false"
                type="number"
                placeholder="Stock"
                v-model="product.stockquantity"
                outlined
                color="#30B868"
                hide-details
                dense
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex md12 lg7 pb-3 py-4 text-right hidden-sm-and-down>
          <span class="px-2">
            <v-btn
              width="250px"
              outlined
              tile
              color="#757575"
              dark
              :ripple="false"
              depressed
              @click="$router.go(-1)"
            >
              <span
                style="
                  font-family: poppinsregular;
                  font-size: 16px;
                  text-transform: none;
                  color: #000000;
                "
              >
                Cancel
              </span>
            </v-btn>
          </span>
          <span class="px-2">
            <v-btn
              width="250px"
              tile
              dark
              color="#30B868"
              depressed
              class="text-capitalize"
              :ripple="false"
              @click="editProduct()"
            >
              Apply
            </v-btn>
          </span>
        </v-flex>
        <v-flex xs6 py-4 px-2 text-right hidden-md-and-up>
          <v-btn
            block
            outlined
            tile
            color="#757575"
            dark
            :ripple="false"
            depressed
            @click="$router.go(-1)"
          >
            <span
              style="
                font-family: poppinsregular;
                font-size: 16px;
                text-transform: none;
                color: #000000;
              "
            >
              Cancel
            </span>
          </v-btn>
        </v-flex>
        <v-flex xs6 py-4 px-2 hidden-md-and-up>
          <v-btn
            block
            tile
            dark
            color="#30B868"
            depressed
            class="text-capitalize"
            :ripple="false"
            @click="editProduct()"
          >
            Apply
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </template>
  <script>
  import StockAdder from "./stockAdder";
  import ImageCropper from "./../../Common/imageCropper";
  import axios from "axios";
  export default {
    props: ["id"],
    components: {
      StockAdder,
      ImageCropper,
    },
    data() {
      return {
        appLoading: false,
        timeout: 5000,
        msg: null,
        ServerError: false,
        showSnackBar: false,
        imageArray: [],
        Images: new FormData(),
        formData: new FormData(),
        selectedFiles: null,
        image: null,
        subSizeArray: [],
        productStock: {},
        radios: null,
        flag: false,
        product: {
          productcode: null,
          category: [],
          photos: [],
          gstPercentage: "",
          hsnCode: "",
          title: "",
          productname: null,
          caption: null,
          description: null,
          stockquantity: null,
          productcost: null,
          // profitmargin: null,
          price: null,
          instock: false,
          unit: "Count",
          size: [],
        },
        productTags: [],
        rules: {
          required: (value) => !!value || "Required.",
        },
        imageCount: 0,
        cropImageDialog: false,
        currentImage: null,
        singleSubcatData: {},
        NameMsg: null,
        newprice: null,
        priceDetails: [],
        categoryArray: [],
      };
    },
    computed: {
      appType() {
        return this.$store.state.userType;
      },
    },
    beforeMount() {
      this.getData();
      this.getCategory();
    },
    methods: {
      imageCropper(payload) {
        this.cropImageDialog = payload.dialog;
        if (payload.image) {
          this.collectImages(payload.image);
        }
      },
      priceChanger(window_data) {
        if (window_data.price) {
          this.product.price = window_data.price;
          if (this.newprice !== window_data.price)
            this.newprice = window_data.price;
        }
      },
      winStepper(window_data) {
        this.productStock = window_data.productStock;
        this.product.size = this.productStock;
        this.product.stockquantity = 0;
        for (var i = 0; i < this.productStock.length; i++) {
          for (var j = 0; j < this.productStock[i].stock.length; j++) {
            this.product.stockquantity =
              this.product.stockquantity +
              parseFloat(this.productStock[i].stock[j].stock);
          }
        }
      },
      subSize(id) {
        for (var i = 0; i < this.categoryArray.length; i++) {
          if (id == this.categoryArray[i]._id) {
            this.subSizeArray = this.categoryArray[i].size;
            this.singleSubcatData = this.categoryArray[i];
          }
        }
      },
      getCategory() {
        this.appLoading = true;
        axios({
          url: "/category/list",
  
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.categoryArray = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      calculate() {
        this.appLoading = true;
        axios({
          url: "/calculate/tax",
  
          method: "POST",
          data: {
            amount: this.product.productcost,
            categoryId: this.product.subcategory,
            type: "Price",
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.priceDetails = response.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      calculateTot() {
        this.appLoading = true;
        axios({
          url: "/calculate/tax",
          method: "POST",
          data: {
            amount: this.priceDetails.totalAmountToCustomer,
            categoryId: this.product.subcategory,
            type: "TotalPrice",
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.priceDetails = response.data;
            this.product.productcost = this.priceDetails.amount;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/admin/seller/productView",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            productId: this.$route.query.id,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              this.product = response.data.data;
              // this.newprice = this.product.price;
              // this.priceDetails.totalAmountToCustomer = this.product.price;
              this.subSize(this.product.category._id);
              this.imageCount =
                this.product.photos.length + this.imageArray.length;
              this.flag = true;
            }
          })
          .catch((err) => {
            // this.ServerError = true;
            console.log(err);
          });
      },
      uploadImages(event) {
        if (this.imageCount < 4) {
          this.currentImage = URL.createObjectURL(event.target.files[0]);
          this.cropImageDialog = true;
        } else {
          this.msg = "Maximum image limit exceeded!";
          this.showSnackBar = true;
          return;
        }
      },
      collectImages(image) {
        this.selectedFiles = image;
        this.Images.append("image", this.selectedFiles);
        this.formData.append("image", this.selectedFiles);
        var img = new Image();
        img.src = window.URL.createObjectURL(image);
        img.onload = () => {
          const urls = URL.createObjectURL(image);
          this.imageArray.push(urls);
          this.imageCount = this.product.photos.length + this.imageArray.length;
          if (this.imageArray) {
            this.image = this.imageArray[0];
          }
        };
      },
      removeImageArray(i) {
        var values = this.formData.getAll("photos");
        values.splice(i, 1);
        this.formData.set("photos", values);
        this.imageArray.splice(i, 1);
      },
      removePhoto(i) {
        var Data = {};
        this.product.photos.splice(i, 1);
        Data["productid"] = this.product._id;
        Data["position"] = i + 1;
        axios({
          method: "POST",
          url: "/admin/product/removeImagesingle",
          data: Data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
              this.imageCount =
                this.product.photos.length + this.imageArray.length;
              this.showSnackBar = true;
              this.msg = "Deleted Successfully";
            } else {
              this.msg = "Can't Delete Image";
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      editProduct() {
        this.appLoading = true;
        this.product.id = this.product._id;
        // this.product.profitmargin = parseFloat(this.product.profitmargin);
        this.product.urlname = this.product.productname.split(/[\s]+/).join("-");
        this.product.productcost = parseFloat(this.product.productcost);
        // this.product.price = parseFloat(this.product.price);
        this.product.price = parseFloat(this.priceDetails.totalAmountToCustomer);
        this.product.stockquantity = Number(this.product.stockquantity);
        if (this.product.stockquantity > 0) this.product.instock = true;
        var urlcall = "/admin/seller/productEdit";
        axios({
          method: "POST",
          url: urlcall,
          data: this.product,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              console.log("data", this.imageArray);
              if (this.imageArray.length > 0) {
                this.uploadAllImages(response.data.id);
              } else {
                this.msg = "Updated Successfully";
              this.showSnackBar = true;
                // this.$router.push("/products?id=" + this.product._id);
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            // this.ServerError = true;
            console.log(err);
          });
      },
      uploadAllImages(item) {
        this.formData.append("productid", item);
        axios({
          method: "POST",
          url: "/Product/UploadImageMultiple",
          data: this.formData,
          headers: {
            "Content-Type": "multipart/form-data",
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status == true) {
                // this.$router.push("/products?id=" + this.item._id);
                this.msg = "Updated Successfully";
              this.showSnackBar = true;
            } else {
              this.msg = "Can't Upload Images";
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
    },
  };
  </script>
  <style scoped>
  .v-input__slot {
    min-height: 36px;
  }
  .v-select > .dropdown-menu {
    text-align: right;
  }
    .radC >>> label {
    font-family: opensansbold;
    color: #000000;
    font-size: 13px;
    padding-left: 10px;
  }
  </style>